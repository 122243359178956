.App {
  text-align: center;
  padding: 20px;
}

textarea {
  width: 300px;
  height: 100px;
}

input {
  margin: 5px;
  padding: 5px;
}

button {
  margin: 10px;
  padding: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
}

div {
  margin: 10px;
}
